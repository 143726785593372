import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import $ from 'jquery'
import gsap from 'gsap'

import './index.scss'

import close__model from '../../icons/interface/close.svg'
import DoubleClick from '../../interface/DoubleClick'
import Cursor from '../../interface/Cursor'

import { useTranslation } from 'react-i18next'

const DetailsFooter = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const modelInfo = useRef()

	const [info, setInfo] = useState(false)

	const openInfo = () => {
		setInfo(true)
	}

	const closeInfo = () => {
		setInfo(false)
	}

	useEffect(() => {
		if (info === true) {
			$(modelInfo.current).removeClass('display-n')
			setTimeout(() => {
				$(modelInfo.current).removeClass('opacity')
			}, 150)
		} else {
			$(modelInfo.current).addClass('opacity')
			setTimeout(() => {
				$(modelInfo.current).addClass('display-n')
			}, 200)
		}
	}, [info])

	const redirect__form = () => {
		setTimeout(() => {
			navigate('/form')
		}, 1800)
		// setSate(!state)
		gsap.to($('.interface__loading'), {
			display: 'block',
		})
		gsap.to($('.interface__loading-start'), 1.2, {
			delay: 0.5,
			left: 0,
			ease: 'Expo.easeInOut',
		})
		gsap.to($('.interface__loading-state'), 1.4, {
			delay: 0.6,
			left: 0,
			ease: 'Expo.easeInOut',
		})
		setTimeout(() => {
			gsap.to($('.interface__loading'), {
				display: 'none',
			})
			gsap.to($('.interface__loading-start'), {
				left: '-100%',
			})
			gsap.to($('.interface__loading-state'), {
				left: '-100%',
			})
			checkButton()
		}, 2000)
	}

	function checkButton() {
		if (window.location.href.substr(-4) === 'form') {
			$('.interface__link').css('display', 'none')
			// $('.interface__back').css('display', 'block')
			$('.interface__close-forms').addClass('display-n')
			$('.interface__close-d-one').addClass('display-n')
			$('.interface__close-d-two').addClass('display-n')

			$('.interface__menu').addClass('interface__menu__close')
			$('.interface__menu').addClass('display-n')
			$('.interface__menu').css('transform', 'rotate(0deg)')
			$('.interface__bg').css('top', '-100%')
			$('.interface__logo').addClass('display-n')
			setTimeout(() => {
				$('.interface__close-forms').removeClass('display-n')
			}, 390)
			setTimeout(() => {
				$('.interface__logo').removeClass('display-n')
			}, 750)
		} else {
			$('.interface__link').css('display', 'block')
			// $('.interface__back').css('display', 'none')
			$('.interface__close-forms').addClass('display-n')
			$('.interface__menu').removeClass('display-n')
		}
	}

	return (
		<div className='details__footer'>
			<div className='details__footer-m display-n opacity' ref={modelInfo}>
				<div className='details__footer-m__wrapper'>
					<div className='details__footer-m__nav'>
						<div className='details__footer-m__close cursor__cross'>
							<img
								className='cursor__cross'
								src={close__model}
								alt='icons'
								onClick={closeInfo}
							/>
						</div>
					</div>
					<div className='details__footer-m__containers'>
						<div className='details__footer-m__text'>
							<div className='details__footer-m__title'>
								Политика конфиденциальности сайта smotrishire.com
							</div>
							<h3>1. Персональная информация Пользователей</h3>
							<p>
								1.1. Сайт собирает, получает доступ и использует в определенных
								Политикой целях персональные данные Пользователей, техническую и
								иную информацию, связанную с Пользователями. 1.2. Техническая
								информация не является персональными данными. Компания
								использует файлы cookies, которые позволяют идентифицировать
								Пользователя. Файлы cookies – это текстовые файлы, доступные
								Компании, для обработки информации об активности Пользователя,
								включая информацию о том, какие страницы посещал Пользователь и
								о времени, которое Пользователь провел на странице. Пользователь
								может отключить возможность использования файлов cookies в
								настройках браузера. 1.3. Также под технической информацией
								понимается информация, которая автоматически передается Компании
								в процессе использования Сайта с помощью установленного на
								устройстве Пользователя программного обеспечения. 1.4. Под
								персональными данными Пользователя понимается информация,
								которую Пользователь предоставляет Компании при заполнении
								заявки на Сайте и последующем использовании Сайта. Обязательная
								для предоставления Компании информация помечена специальным
								образом. Иная информация предоставляется Пользователем на его
								усмотрение. 1.5. Компания также может обрабатывать данные,
								сделанные общедоступными субъектом персональных данных или
								подлежащие опубликованию или обязательному раскрытию в
								соответствии с законом. 1.6. Компания не проверяет достоверность
								персональной информации, предоставляемой Пользователем, и не
								имеет возможности оценивать его дееспособность. Однако Компания
								исходит из того, что Пользователь предоставляет достоверную и
								достаточную персональную информацию о себе и поддерживает эту
								информацию в актуальном состоянии.
							</p>
							<h3>2. Цели обработки персональной информации Пользователей</h3>
							<p>
								2.1. Главная цель Компании при сборе персональных данных —
								предоставление информационных, консультационных услуг
								Пользователям. Пользователи соглашаются с тем, что Компания
								также может использовать их персональные данные для:
								Идентификация стороны в рамках предоставляемых услуг;
								Предоставления услуг и клиентской поддержки по запросу
								Пользователей; Улучшение качества услуг, удобства их
								использования, разработка и развитие Сайта, устранения
								технических неполадок или проблем с безопасностью; Анализ для
								расширения и совершенствования услуг, информационного наполнения
								и рекламы услуг; Информирования Пользователей об услугах,
								целевом маркетинге, обновлении услуг и рекламных предложениях на
								основании информационных предпочтений Пользователей;
								Таргетирование рекламных материалов; рассылки индивидуальных
								маркетинговых сообщений посредством электронной почты;
								Проведение статистических и иных исследований на основе
								обезличенных данных. 2.2. Компания использует техническую
								информацию обезличено в целях, указанных в пункте 2.1.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='details__footer-w'>
				<DoubleClick classN='interface__link'>
					<button
						onClick={redirect__form}
						className='interface__link cursor__a_r'
					>
						{t('project')}
					</button>
				</DoubleClick>
				<div className='details__footer-year'>©2022 {t('tit')}</div>
				<div className='details__footer-contact'>
					<a
						href='https://vk.com/smotrishire_agency'
						className='details__footer-contact-i cursor__act'
						target='_blank'
						rel='noopener noreferrer'
					>
						VKONTAKTE
					</a>
					<a
						href='https://vimeo.com/smotrishire'
						className='details__footer-contact-i cursor__act'
						target='_blank'
						rel='noopener noreferrer'
					>
						VIMEO
					</a>
					<a
						href='mailto:pr@smotrishire.com'
						className='details__footer-contact-i cursor__act'
					>
						pr@smotrishire.com
					</a>
					<a
						href='tel:+79223773777'
						className='details__footer-contact-i cursor__act'
					>
						+7 922 377 3777
					</a>
				</div>
				<div className='details__footer-info cursor__act' onClick={openInfo}>
					{t('pol')}
				</div>
			</div>
			<DoubleClick />
			<Cursor />
		</div>
	)
}

export default DetailsFooter
