import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, {
// 	Pagination,
// 	Autoplay,
// 	Mousewheel,
// 	EffectFade,
// } from 'swiper'

import { EffectFade, Mousewheel, Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import * as Scroll from 'react-scroll';

import gsap from 'gsap';

import { useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';
import DetailsOneSlide from '../DetailsOneSlide/DetailsOneSlide';
import DetailsTwoSlide from '../DetailsTwoSlide/DetailsTwoSlide';

import Cursor from '../../interface/Cursor';

import { useTranslation } from 'react-i18next';
import '../../util/i18next';

import DoubleClick from '../../interface/DoubleClick';

import $ from 'jquery';
import Preloading from '../Preloading/Preloading';
import SliderOne from '../SliderOne/SliderOne';
import './index.scss';

import ReactPlayer from 'react-player';

import icons__player from '../../icons/slider/play.svg';
import sound__play from '../../icons/slider/sound__play.svg';
import sound__stop from '../../icons/slider/sound__stop.svg';

//! Video Slide

import twoSlideVideo from '../../img/slider/2.two/1.m4v';

import threeSlideVideo from '../../img/slider/3.three/showreel.mov';

import fourSlideVideo from '../../img/slider/4.four/1.mov';

import fiveSlideVideo from '../../img/slider/5.five/1.mov';

//! Poster Video Slide

// import oneSlidePoster_1 from '../../img/slider/1.one/slider-1.png'
// import oneSlidePoster_2 from '../../img/slider/1.one/slider-1.png'
// import oneSlidePoster_3 from '../../img/slider/1.one/slider-1.png'
// import oneSlidePoster_4 from '../../img/slider/1.one/slider-1.png'
// import oneSlidePoster_5 from '../../img/slider/1.one/slider-1.png'

// import twoSlidePoster from '../../img/slider/2.tow/slider-2.png'

// import threeSlidePoster from '../../img/slider/3.three/slider-3.png'

//! -------------------------->

let Links = Scroll.Link;

// SwiperCore.use([Autoplay, Mousewheel, Pagination, EffectFade])

export default function Home() {
	const { t } = useTranslation();

	const homeSliders = ['PORTFOLIO', 'TEAM', 'SHOWREEL', 'COURSES', 'CONTACTS'];

	const pagination = {
		el: '.interface__controller',
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + homeSliders[index] + '</span>';
		},
	};

	const navigation = true;

	const [oneSlide, setOneSlide] = useState(false);
	const [twoSlide, setTwoSlide] = useState(false);

	const [slideThreeMute, setSlideThreeMute] = useState(false);

	const mutedVideoButton = () => {
		setSlideThreeMute(!slideThreeMute);
		if ($('.slider__three-mute').hasClass('slider__three-mute-a')) {
			$('.slider__three-mute').removeClass('slider__three-mute-a');
		} else {
			$('.slider__three-mute').addClass('slider__three-mute-a');
		}
	};

	const [scrollSlider, setScrollSlider] = useState(true);

	// const scrollSlider = {
	// 	invert: true,
	// }

	const [oneSlideDelay, setOneSlideDelay] = useState(false);

	const [plays, setPlays] = useState(false);

	const controlesPlayer = () => {
		if ($('.slider__three-btn img').hasClass('opacity')) {
			setPlays(false);
			$('.slider__three-btn img').removeClass('opacity');
			$('.slider__three-title').removeClass('opacity');
		} else {
			setPlays(true);
			$('.slider__three-btn img').addClass('opacity');
			// $('.slider__three-title').addClass('opacity')
		}
	};

	setTimeout(() => {
		setOneSlideDelay(true);
	}, 2300);

	const DetailsOne = () => {
		setOneSlide(!oneSlide);

		checkArrowScroll();

		$('.home').css('height', '0');
		$('.preloading__wrapper').css('height', '0');
		$('.preloading__letter').css('font-size', '0');

		$('.interface__close-d-one').removeClass('display-n');
		$('.interface__bg-media').removeClass('display-n');
		$('.interface__menu').removeClass('interface__menu__close');
		$('.interface__menu').addClass('display-n');
		$('.interface__bg').css('top', '-100%');
		$('.interface__menu').css('transform', 'rotate(0deg)');
	};

	const checkArrowScroll = () => {
		window.addEventListener('scroll', function () {
			if (document.documentElement.scrollTop >= 230) {
				$('.arrowTopOne').removeClass('display-n');
				$('.arrowTopTwo').removeClass('display-n');
			}
		});
	};

	const DetailsTwo = () => {
		setTwoSlide(!twoSlide);

		checkArrowScroll();

		$('.home').css('height', '0');
		$('.preloading__wrapper').css('height', '0');
		$('.preloading__letter').css('font-size', '0');

		$('.interface__close-d-two').removeClass('display-n');
		$('.interface__bg-media').removeClass('display-n');
		$('.interface__menu').removeClass('interface__menu__close');
		$('.interface__menu').addClass('display-n');
		$('.interface__bg').css('top', '-100%');
		$('.interface__menu').css('transform', 'rotate(0deg)');
	};

	const [animChange, setAnimChange] = useState(0);

	const closeDetails = e => {
		setOneSlide(false);
		setTwoSlide(false);

		setPlays(false);
		$('.slider__three-btn img').removeClass('opacity');
		$('.slider__three-title').removeClass('opacity');

		setAnimChange(e.activeIndex);
	};

	//! Animation

	useEffect(() => {
		// if (animChange !== null) {
		// }
		//! Opacity
		gsap.to($('.anim__op'), {
			// left: '20%',
			ease: 'Expo.easeInOut',
			duration: 1,
			delay: 0.3,
			opacity: 0,
		});

		if (animChange === 0) {
			//! Opacity
			gsap.to($('.slider__one .anim__op'), {
				// left: '20%',
				ease: 'Expo.easeInOut',
				duration: 1,
				// x: '0%',
				delay: 0.3,
				opacity: 1,
			});
		}
		if (animChange === 1) {
			//! Opacity
			gsap.to($('.slider__two .anim__op'), {
				// left: '20%',
				ease: 'Expo.easeInOut',
				duration: 1,
				// x: '0%',
				delay: 0.3,
				opacity: 1,
			});
		}
		if (animChange === 2) {
			//! Opacity
			gsap.to($('.slider__three .anim__op'), {
				// left: '20%',
				ease: 'Expo.easeInOut',
				duration: 1,
				// x: '0%',
				delay: 0.3,
				opacity: 1,
			});
		}
		if (animChange === 3) {
			//! Opacity
			gsap.to($('.slider__four .anim__op'), {
				// left: '20%',
				ease: 'Expo.easeInOut',
				duration: 1,
				// x: '0%',
				delay: 0.3,
				opacity: 1,
			});
		}
		if (animChange === 4) {
			//! Opacity
			gsap.to($('.slider__five .anim__op'), {
				// left: '20%',
				ease: 'Expo.easeInOut',
				duration: 1,
				// x: '0%',
				delay: 0.3,
				opacity: 1,
			});
		}
	}, [animChange]);

	const readSlider = useRef();

	useEffect(() => {
		if (window.location.href.substr(-4) === 'form') {
			$('.interface__link').css('display', 'none');
			$('.interface__back').css('display', 'block');
			$('.interface__bg-media').addClass('display-n');
		} else {
			$('.interface__link').css('display', 'block');
			$('.interface__back').css('display', 'none');
		}

		$('.swiper-button-next, .swiper-button-prev').addClass('cursor__act');
	}, []);

	$('.interface__close-d-one').on('click', () => {
		$('.interface__close-d-one').addClass('display-n');
		$('.interface__bg-media').addClass('display-n');
		$('.interface__bg-media').addClass('display-n');
		$('.interface__menu').removeClass('display-n');

		$('.home').css('height', 'calc(var(--vh, 1vh) * 100)');
		$('.preloading__wrapper').css('height', '100%');
		$('.arrowTopOne').addClass('display-n');
		$('.arrowTopOne').addClass('opacity-t');
		$('.arrowTopTwo').addClass('display-n');
		$('.arrowTopTwo').addClass('opacity-t');
		setTimeout(() => {
			$('.arrowTopOne').addClass('display-n');
			$('.arrowTopOne').removeClass('opacity-t');
			$('.arrowTopTwo').addClass('display-n');
			$('.arrowTopTwo').removeClass('opacity-t');
			setOneSlide(false);
			$('.preloading__letter').css('font-size', '11px');
		}, 1050);
	});

	$('.interface__close-d-two').on('click', () => {
		$('.interface__close-d-two').addClass('display-n');
		$('.interface__bg-media').addClass('display-n');
		$('.interface__menu').removeClass('display-n');

		$('.home').css('height', 'calc(var(--vh, 1vh) * 100)');
		$('.preloading__wrapper').css('height', '100%');
		$('.arrowTopOne').addClass('display-n');
		$('.arrowTopOne').addClass('opacity-t');
		$('.arrowTopTwo').addClass('display-n');
		$('.arrowTopTwo').addClass('opacity-t');
		setTimeout(() => {
			$('.arrowTopOne').addClass('display-n');
			$('.arrowTopOne').removeClass('opacity-t');
			$('.arrowTopTwo').addClass('display-n');
			$('.arrowTopTwo').removeClass('opacity-t');
			setTwoSlide(false);
			$('.preloading__letter').css('font-size', '11px');
		}, 1050);
	});

	const navigate = useNavigate();

	const redirect__form = () => {
		setTimeout(() => {
			navigate('/form');
		}, 1800);
		// setSate(!state)
		gsap.to($('.interface__loading'), {
			display: 'block',
		});
		gsap.to($('.interface__loading-start'), 1.2, {
			delay: 0.5,
			left: 0,
			ease: 'Expo.easeInOut',
		});
		gsap.to($('.interface__loading-state'), 1.4, {
			delay: 0.6,
			left: 0,
			ease: 'Expo.easeInOut',
		});
		setTimeout(() => {
			gsap.to($('.interface__loading'), {
				display: 'none',
			});
			gsap.to($('.interface__loading-start'), {
				left: '-100%',
			});
			gsap.to($('.interface__loading-state'), {
				left: '-100%',
			});
			checkButton();
		}, 2000);
	};

	function checkButton() {
		if (window.location.href.substr(-4) === 'form') {
			$('.interface__link').css('display', 'none');
			$('.interface__close-forms').addClass('display-n');
			$('.interface__close-d-one').addClass('display-n');
			$('.interface__bg-media').addClass('display-n');
			$('.interface__close-d-two').addClass('display-n');

			$('.interface__menu').addClass('interface__menu__close');
			$('.interface__menu').addClass('display-n');
			$('.interface__menu').css('transform', 'rotate(0deg)');
			$('.interface__bg').css('top', '-100%');
			$('.interface__logo').addClass('display-n');
			setTimeout(() => {
				$('.interface__close-forms').removeClass('display-n');
			}, 390);
			setTimeout(() => {
				$('.interface__logo').removeClass('display-n');
			}, 750);
		} else {
			$('.interface__link').css('display', 'block');
			$('.interface__close-forms').addClass('display-n');
			$('.interface__menu').removeClass('display-n');
		}
	}

	// const [my_swiper, set_my_swiper] = useState()

	// useEffect(() => {
	// 	$('.interface__controller-i').on('click', function () {
	// 		const id = $(this).attr('data-id')
	// 		// my_swiper.slideTo(id)
	// 		console.log(my_swiper)
	// 	})
	// }, [])

	return (
		<>
			{/* <div id='topScroll'></div> */}
			<Preloading />
			{/* <Links
				to='topScroll'
				spy={true}
				smooth={true}
				duration={1000}
				delay={100}
				className='arrowTop display-n cursor__act'
			>
				<img src={arrowsTopIcons} alt='icons' />
			</Links> */}
			<div className='home'>
				<Swiper
					pagination={pagination}
					className='homeSliders'
					ref={readSlider}
					allowTouchMove={false}
					navigation={navigation}
					effect={'fade'}
					speed={1200}
					mousewheel={scrollSlider}
					modules={[EffectFade, Navigation, Mousewheel, Pagination]}
					onSlideChange={e => closeDetails(e)}
					// resistance={true}
					// resistanceRatio={0.1}
				>
					<SwiperSlide>
						<div className='slider__one'>
							<DoubleClick classN='slider__one-open'>
								<button className='slider__one-open cursor__a_d anim__op' onClick={DetailsOne}>
									{t('more')}
								</button>
							</DoubleClick>
							{/* <DoubleClick classN='slider__one-open-hide'>
								<button
									className='slider__one-open-hide cursor__a_d'
									onClick={DetailsOne}
								></button>
							</DoubleClick> */}
							<DoubleClick classN='interface__link'>
								<button onClick={redirect__form} className='interface__link cursor__a_r anim__op'>
									{t('project')}
								</button>
							</DoubleClick>
							{oneSlideDelay ? <SliderOne /> : null}
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='slider__two'>
							<DoubleClick classN='slider__two-open'>
								<button className='slider__two-open cursor__a_d anim__op' onClick={DetailsTwo}>
									{t('more')}
								</button>
							</DoubleClick>

							{/* <DoubleClick classN='slider__two-open-hide'>
								<button
									className='slider__two-open-hide cursor__a_d'
									onClick={DetailsTwo}
								></button>
							</DoubleClick> */}

							<DoubleClick classN='interface__link'>
								<button
									onClick={redirect__form}
									className='interface__link cursor__a_r anim__op'
									style={{ position: 'absolute' }}
								>
									{t('project')}
								</button>
							</DoubleClick>
							<div className='slider__two-title anim__op'>{t('team')}</div>
							<div className='slider__two-video'>
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={twoSlideVideo} type='video/mp4' />
								</video>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='slider__three'>
							<DoubleClick classN='interface__link'>
								<button
									onClick={redirect__form}
									className='interface__link cursor__a_r anim__op'
									style={{ position: 'absolute' }}
								>
									{t('project')}
								</button>
							</DoubleClick>
							<div className='slider__three-title anim__op'>SHOWREEL</div>
							<button
								className='slider__three-mute cursor__act anim__op'
								onClick={mutedVideoButton}
							>
								<img src={sound__play} alt='icons' />
								<img src={sound__stop} alt='icons' />
							</button>
							<div className='slider__three-wrapper'>
								<ReactPlayer
									url={threeSlideVideo}
									className='react-player'
									playing={plays}
									width='100%'
									height='100%'
									loop={true}
									volume={null}
									muted={slideThreeMute}
								/>
							</div>
							<button
								className='slider__three-btn cursor__play anim__op'
								onClick={() => controlesPlayer()}
							>
								<img src={icons__player} alt='icons' />
							</button>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='slider__four'>
							{/* <img src={fourSlidePoster} alt='img' /> */}
							<video autoPlay muted loop playsInline preload='auto'>
								<source src={fourSlideVideo} type='video/mp4' />
							</video>
							<div className='slider__four-title anim__op'>{t('courses')}</div>
							<DoubleClick classN='interface__link'>
								<button
									onClick={redirect__form}
									className='interface__link cursor__a_r anim__op'
									style={{ position: 'absolute' }}
								>
									{t('project')}
								</button>
							</DoubleClick>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='slider__five'>
							{/* <img src={fiveSlidePoster} alt='img' /> */}
							<video autoPlay muted loop playsInline preload='auto'>
								<source src={fiveSlideVideo} type='video/mp4' />
							</video>
							<DoubleClick classN='interface__link'>
								<button
									onClick={redirect__form}
									className='interface__link cursor__a_r anim__op'
									style={{ position: 'absolute' }}
								>
									{t('project')}
								</button>
							</DoubleClick>
							<div className='slider__five-title anim__op'>{t('contact')}</div>
							<ul className='slider__five-contact anim__op'>
								<li className='slider__five-link cursor__act'>
									<a href='https://vimeo.com/smotrishire' target='_blank' rel='noopener noreferrer'>
										VIMEO
									</a>
								</li>
								<li className='slider__five-link cursor__act'>
									<a
										href='https://vk.com/smotrishire_agency'
										target='_blank'
										rel='noopener noreferrer'
									>
										VKONTAKTE
									</a>
								</li>
								<li className='slider__five-link cursor__act'>
									<a href='tel:+79223773777'>+7 922 377 3777</a>
									<a href='tel:+79124890448'>+7 912 489 0448</a>
								</li>
								<li className='slider__five-link cursor__act'>
									<a href='mailto:pr@smotrishire.com'>pr@smotrishire.com</a>
								</li>
								<li className='slider__five-address cursor__act'>
									<a href='https://yandex.ru/maps/-/CCUFrCrzpA'>
										г. Москва,
										<br />
										ул. Новый Арбат д. 21,
										<br />
										14 этаж, офис 1407
									</a>
								</li>
							</ul>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<div id='anchorDetails'></div>
			<Cursor />
			{oneSlide ? <DetailsOneSlide /> : null}
			{twoSlide ? <DetailsTwoSlide /> : null}
		</>
	);
}
