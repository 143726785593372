import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import gsap from 'gsap'
import $ from 'jquery'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import './index.scss'

import WOW from 'wowjs'

import DetailsFooter from '../DetailsFooter/DetailsFooter'

import cg__icons from '../../icons/details/1.one/cg.svg'
import corporate__icons from '../../icons/details/1.one/corporate.svg'
import event__icons from '../../icons/details/1.one/event.svg'
import commercial__icons from '../../icons/details/1.one/commercial.svg'
import music__icons from '../../icons/details/1.one/music.svg'

import cg__icons_m from '../../icons/details/1.one/cg_.svg'
import corporate__icons_m from '../../icons/details/1.one/corporate_.svg'
import event__icons_m from '../../icons/details/1.one/event_.svg'
import commercial__icons_m from '../../icons/details/1.one/commercial_.svg'
import music__icons_m from '../../icons/details/1.one/music_.svg'

import ModalVideo from '../ModalVideo/ModalVideo'

// Commercial

import p_commercial_1 from '../../img/detail_1/Commercial/VEGA/1.jpg'
import v_commercial_1 from '../../img/detail_1/Commercial/VEGA/1.m4v'

import p_commercial_2 from '../../img/detail_1/Commercial/Novakid/1.jpg'
import v_commercial_2 from '../../img/detail_1/Commercial/Novakid/1.m4v'

import p_commercial_3 from '../../img/detail_1/Commercial/LOOKBOOK/1.jpg'
import v_commercial_3 from '../../img/detail_1/Commercial/LOOKBOOK/1.m4v'

import p_commercial_4 from '../../img/detail_1/Commercial/BEONIX/1.jpg'
import v_commercial_4 from '../../img/detail_1/Commercial/BEONIX/1.mov'

// Music

import p_music_1 from '../../img/detail_1/Music/Нагло/1.jpg'
import v_music_1 from '../../img/detail_1/Music/Нагло/1.m4v'

import p_music_2 from '../../img/detail_1/Music/Панацея/1.jpg'
import v_music_2 from '../../img/detail_1/Music/Панацея/1.m4v'

import p_music_3 from '../../img/detail_1/Music/Ценник/1.jpg'
import v_music_3 from '../../img/detail_1/Music/Ценник/1.m4v'

import p_music_4 from '../../img/detail_1/Music/Денис/1.jpg'
import v_music_4 from '../../img/detail_1/Music/Денис/1.m4v'

import p_music_5 from '../../img/detail_1/Music/Антон/1.jpg'
import v_music_5 from '../../img/detail_1/Music/Антон/1.m4v'

// Corporate

import p_corporate_1 from '../../img/detail_1/Corporate/Microsoft/1.jpg'
import v_corporate_1 from '../../img/detail_1/Corporate/Microsoft/1.mov'

import p_corporate_2 from '../../img/detail_1/Corporate/qatar_int/1.jpg'
import v_corporate_2 from '../../img/detail_1/Corporate/qatar_int/1.m4v'

// EVENT

import p_event_1 from '../../img/detail_1/Event/Aliexpress/1.jpg'
import v_event_1 from '../../img/detail_1/Event/Aliexpress/1.mov'

import p_event_2 from '../../img/detail_1/Event/Gazgolder/1.jpg'
import v_event_2 from '../../img/detail_1/Event/Gazgolder/1.mov'

import p_event_3 from '../../img/detail_1/Event/Community/1.jpg'
import v_event_3 from '../../img/detail_1/Event/Community/1.mov'

import p_event_4 from '../../img/detail_1/Event/Qatar/1.jpg'
import v_event_4 from '../../img/detail_1/Event/Qatar/1.mov'

// CG

import p_cg_1 from '../../img/detail_1/CG/Epic/1.jpg'
import v_cg_1 from '../../img/detail_1/CG/Epic/1.m4v'

import p_cg_2 from '../../img/detail_1/CG/Relictum/1.jpg'
import v_cg_2 from '../../img/detail_1/CG/Relictum/1.m4v'
import Cursor from '../../interface/Cursor'

import * as Scroll from 'react-scroll'
import arrowsTopIcons from '../../icons/details/up.svg'
let Links = Scroll.Link

const bd__one = {
	// Commercial

	сommercial__1: {
		name: 'VEGA PLATFORM',
		photo: p_commercial_1,
		video: v_commercial_1,
		link: 'https://player.vimeo.com/video/670854934?h=d25e2fe978',
	},
	сommercial__2: {
		name: 'NOVAKID',
		photo: p_commercial_2,
		video: v_commercial_2,
		link: 'https://player.vimeo.com/video/582111931?h=b7bf424ff2',
	},
	сommercial__3: {
		name: 'LOOKBOOK',
		photo: p_commercial_3,
		video: v_commercial_3,
		link: 'https://player.vimeo.com/video/396163807?h=2f3d332e7f',
	},
	сommercial__4: {
		name: 'BEONIX',
		photo: p_commercial_4,
		video: v_commercial_4,
		link: 'https://player.vimeo.com/video/699088328?h=c0cad9d558',
	},

	// Music

	music__1: {
		name: 'ZOMB - Нагло',
		photo: p_music_1,
		video: v_music_1,
		link: 'https://player.vimeo.com/video/670373224?h=17b1bdc399',
	},
	music__2: {
		name: 'ZOMB - Панацея',
		photo: p_music_2,
		video: v_music_2,
		link: 'https://player.vimeo.com/video/360122918?h=213ae947cb',
	},
	music__3: {
		name: 'DIMMA URIH - Ценник',
		photo: p_music_3,
		video: v_music_3,
		link: 'https://player.vimeo.com/video/368653839?h=94e6d2971a',
	},
	music__4: {
		name: 'ILLUZIA DENIS A - EXODUS',
		photo: p_music_4,
		video: v_music_4,
		link: 'https://player.vimeo.com/video/698384689?h=b0bb6d127b',
	},
	music__5: {
		name: 'ILLUZIA ANTIDJ - XEON',
		photo: p_music_5,
		video: v_music_5,
		link: 'https://player.vimeo.com/video/698380948?h=f087a486a',
	},

	// Corporate

	corporate__1: {
		name: 'Microsoft Built',
		photo: p_corporate_1,
		video: v_corporate_1,
		link: 'https://player.vimeo.com/video/699894763?h=b87ca44a1f',
	},
	corporate__2: {
		name: 'Radugadesign - Qatar',
		photo: p_corporate_2,
		video: v_corporate_2,
		link: 'https://player.vimeo.com/video/699898104?h=b3d3786324',
	},

	// Event

	event__1: {
		name: 'Aliexpress',
		photo: p_event_1,
		video: v_event_1,
		link: 'https://player.vimeo.com/video/699088235?h=7307c44302',
	},
	event__2: {
		name: 'Gazgolder',
		photo: p_event_2,
		video: v_event_2,
		link: 'https://player.vimeo.com/video/699088404?h=cbd1d8dcad',
	},
	event__3: {
		name: 'Community',
		photo: p_event_3,
		video: v_event_3,
		link: 'https://player.vimeo.com/video/581833049?h=da51632a79',
	},
	event__4: {
		name: 'Radugadesign - Qatar',
		photo: p_event_4,
		video: v_event_4,
		link: 'https://player.vimeo.com/video/670387291?h=5c94ec4791',
	},

	// Event

	cg__1: {
		name: 'Epicloto',
		photo: p_cg_1,
		video: v_cg_1,
		link: 'https://player.vimeo.com/video/700429930?h=5394ad2538',
	},
	cg__2: {
		name: 'Relictum',
		photo: p_cg_2,
		video: v_cg_2,
		link: 'https://player.vimeo.com/video/700417162?h=8d77066335',
	},
}

const DetailsOneListVideo = {
	// Commercial

	1: bd__one.сommercial__1.link,
	2: bd__one.сommercial__2.link,
	3: bd__one.сommercial__3.link,
	4: bd__one.сommercial__4.link,

	// Music

	5: bd__one.music__1.link,
	6: bd__one.music__2.link,
	7: bd__one.music__3.link,
	8: bd__one.music__4.link,
	9: bd__one.music__5.link,

	// Corporate

	10: bd__one.corporate__1.link,
	11: bd__one.corporate__2.link,

	// Event

	12: bd__one.event__1.link,
	13: bd__one.event__2.link,
	14: bd__one.event__3.link,
	15: bd__one.event__4.link,

	// CG

	16: bd__one.cg__1.link,
	17: bd__one.cg__2.link,
}

const DetailsOneSlide = () => {
	const [states, setSates] = useState(false)

	const navigate = useNavigate()

	const d_loader__start = useRef()
	const d_loader__end = useRef()

	if (states) {
		setTimeout(() => {
			navigate('/')
		}, 3000)
	}

	const details = useRef()

	useEffect(() => {
		gsap.to(d_loader__start.current, 2, {
			delay: 0,
			left: 'auto',
			ease: 'Expo.easeInOut',
		})
		gsap.to(d_loader__start.current, 2, {
			delay: 2.5,
			right: '-100%',
			ease: 'Expo.easeInOut',
		})
		gsap.to(d_loader__end.current, 2, {
			delay: 0.5,
			left: 'auto',
			ease: 'Expo.easeInOut',
		})
		gsap.to(d_loader__end.current, 2, {
			delay: 2,
			right: '-100%',
			ease: 'Expo.easeInOut',
		})
		for (let i = 1; i <= $('.detailOne__block-items').length; i++) {
			$(`#detailOne__${i}`).on('click', () => {
				$('.ModalVideo__video iframe').attr('src', `${DetailsOneListVideo[i]}`)
				$('body').css('overflow', 'hidden')
				setTimeout(() => {
					$('.ModalVideo').css('display', 'block')
				}, 50)
				setTimeout(() => {
					$('.ModalVideo').css('opacity', '1')
				}, 150)
			})
		}

		$('.detailOne__block-items').hover(
			function () {
				$(this).children('img').css('opacity', '0')
				$(this).children('video').css('opacity', '1')
			},
			function () {
				$(this).children('img').css('opacity', '1')
				$(this).children('video').css('opacity', '0')
			}
		)

		if (window.innerWidth <= 900) {
			$('.detailOne__block-items').children('video').remove()
		}

		new WOW.WOW({
			live: false,
		}).init()

		if (window.innerWidth <= 900) {
			$('.animated').removeClass('animated')
		}

		$('.ModalVideo__video').hover(
			() => {
				$('.cursor').addClass('cursor__h')
				$('.cursor2').addClass('cursor2__h')
			},
			() => {
				$('.cursor').removeClass('cursor__h')
				$('.cursor2').removeClass('cursor2__h')
			}
		)

		$('#detailOne').scroll(function () {
			if (this.scrollTop >= 230) {
				$('.arrowTopOne').removeClass('display-n')
			}
			console.log(1)
		})

		$('.arrowTopOne').on('click', () => {
			details.current.scrollTo(0, 0)
		})
	}, [])

	// const checkArrowScroll = () => {
	// 	window.addEventListener('scroll', function () {
	// 		if (document.documentElement.scrollTop >= 230) {
	// 			$('.arrowTop').removeClass('display-n')
	// 		}
	// 	})
	// }

	return (
		<>
			<ParallaxProvider>
				<div className='arrowTopOne display-n cursor__act'>
					<img src={arrowsTopIcons} alt='icons' />
				</div>
				<section className='detailOne' ref={details} id='detailOne'>
					<div id='topScroll'></div>

					<div className='detailOne__block-decor'>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
						<div className='detailOne__block-decor-i'></div>
					</div>
					<div className='detailOne__block'>
						<Parallax
							translateY={[30, -30]}
							className='detailOne__block-title'
							style={{ height: '700px', visibility: 'visible' }}
						>
							<div>
								<img src={commercial__icons} alt='icons' />
							</div>
						</Parallax>
						<div className='detailOne__block-titles'>
							<img src={commercial__icons_m} alt='icons' />
						</div>
						<ul className='detailOne__block-list'>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__1'
							>
								<img src={bd__one.сommercial__1.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.сommercial__1.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.сommercial__1.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__2'
							>
								<img src={bd__one.сommercial__2.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.сommercial__2.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.сommercial__2.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__3'
							>
								<img src={bd__one.сommercial__3.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.сommercial__3.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.сommercial__3.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__4'
							>
								<img src={bd__one.сommercial__4.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.сommercial__4.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.сommercial__4.name}</h3>
								</div>
							</li>
						</ul>
					</div>
					<div className='detailOne__block detailOne__block-left'>
						<Parallax
							translateY={[30, -30]}
							className='detailOne__block-title'
							style={{ height: '700px', visibility: 'visible' }}
						>
							<div>
								<img src={music__icons} alt='icons' />
							</div>
						</Parallax>
						<div className='detailOne__block-titles'>
							<img src={music__icons_m} alt='icons' />
						</div>
						<ul className='detailOne__block-list'>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__5'
							>
								<img src={bd__one.music__1.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.music__1.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.music__1.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__6'
							>
								<img src={bd__one.music__2.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.music__2.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.music__2.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__7'
							>
								<img src={bd__one.music__3.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.music__3.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.music__3.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__8'
							>
								<img src={bd__one.music__4.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.music__4.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.music__4.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__9'
							>
								<img src={bd__one.music__5.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.music__5.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.music__5.name}</h3>
								</div>
							</li>
						</ul>
					</div>
					<div className='detailOne__block'>
						<Parallax
							translateY={[30, -30]}
							className='detailOne__block-title'
							style={{ height: '700px', visibility: 'visible' }}
						>
							<div>
								<img src={corporate__icons} alt='icons' />
							</div>
						</Parallax>
						<div className='detailOne__block-titles'>
							<img src={corporate__icons_m} alt='icons' />
						</div>
						<ul className='detailOne__block-list'>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__10'
							>
								<img src={bd__one.corporate__1.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.corporate__1.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.corporate__1.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__11'
							>
								<img src={bd__one.corporate__2.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.corporate__2.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.corporate__2.name}</h3>
								</div>
							</li>
						</ul>
					</div>
					<div className='detailOne__block detailOne__block-left'>
						<Parallax
							translateY={[30, -30]}
							className='detailOne__block-title'
							style={{ height: '700px', visibility: 'visible' }}
						>
							<div>
								<img src={event__icons} alt='icons' />
							</div>
						</Parallax>
						<div className='detailOne__block-titles'>
							<img src={event__icons_m} alt='icons' />
						</div>
						<ul className='detailOne__block-list'>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__12'
							>
								<img src={bd__one.event__1.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.event__1.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.event__1.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__13'
							>
								<img src={bd__one.event__2.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.event__2.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.event__2.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__14'
							>
								<img src={bd__one.event__3.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.event__3.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.event__3.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__15'
							>
								<img src={bd__one.event__4.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.event__4.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.event__4.name}</h3>
								</div>
							</li>
						</ul>
					</div>
					<div className='detailOne__block'>
						<Parallax
							translateY={[30, -30]}
							className='detailOne__block-title'
							style={{ height: '300px', visibility: 'visible' }}
						>
							<div>
								<img src={cg__icons} alt='icons' />
							</div>
						</Parallax>
						<div className='detailOne__block-titles'>
							<img src={cg__icons_m} alt='icons' />
						</div>
						<ul className='detailOne__block-list'>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__16'
							>
								<img src={bd__one.cg__1.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.cg__1.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.cg__1.name}</h3>
								</div>
							</li>
							<li
								className='detailOne__block-items cursor__play'
								id='detailOne__17'
							>
								<img src={bd__one.cg__2.photo} alt='img' />
								<video autoPlay muted loop playsInline preload='auto'>
									<source src={bd__one.cg__2.video} type='video/mp4' />
								</video>
								<div className='detailOne__block-items-info'>
									<h3>{bd__one.cg__2.name}</h3>
								</div>
							</li>
						</ul>
					</div>

					<DetailsFooter />
				</section>
				<ModalVideo />
				<Cursor />
			</ParallaxProvider>
		</>
	)
}

export default DetailsOneSlide
