import React, { useEffect } from 'react'
import $ from 'jquery'

const Cursor = () => {
	//! Cursor

	useEffect(() => {
		//! Active

		$('.cursor__act').hover(
			() => {
				$('.cursor2').addClass('cursor2__a')
			},
			() => {
				$('.cursor2').removeClass('cursor2__a')
			}
		)

		//! Arrow down

		$('.cursor__a_d').hover(
			() => {
				$('.cursor2').addClass('cursor2__a')
				$('#cursor__a_d').removeClass('opacity-t')
			},
			() => {
				$('.cursor2').removeClass('cursor2__a')
				$('#cursor__a_d').addClass('opacity-t')
			}
		)

		//! Arrow right

		$('.cursor__a_r').hover(
			() => {
				$('.cursor2').addClass('cursor2__a')
				$('#cursor__a_r').removeClass('opacity-t')
			},
			() => {
				$('.cursor2').removeClass('cursor2__a')
				$('#cursor__a_r').addClass('opacity-t')
			}
		)

		//! Cross

		$('.cursor__cross').hover(
			() => {
				$('.cursor2').addClass('cursor2__a')
				$('#cursor__cross').removeClass('opacity-t')
			},
			() => {
				$('.cursor2').removeClass('cursor2__a')
				$('#cursor__cross').addClass('opacity-t')
			}
		)

		//! Play

		$('.cursor__play').hover(
			() => {
				$('.cursor2').addClass('cursor2__a')
				$('#cursor__play').removeClass('opacity-t')
			},
			() => {
				$('.cursor2').removeClass('cursor2__a')
				$('#cursor__play').addClass('opacity-t')
			}
		)
	}, [])

	return <></>
}

export default Cursor
