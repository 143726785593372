import React, { useRef, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import './index.scss'

import oneSlideVideo_1 from '../../img/slider/1.one/1.mov'
import oneSlideVideo_2 from '../../img/slider/1.one/2.mov'
import oneSlideVideo_3 from '../../img/slider/1.one/3.mov'
import oneSlideVideo_4 from '../../img/slider/1.one/4.mov'
import oneSlideVideo_5 from '../../img/slider/1.one/5.m4v'

import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper'

// SwiperCore.use([Autoplay, EffectFade, Pagination])

import { useTranslation } from 'react-i18next'
import '../../util/i18next'

const SliderOne = () => {
	const { t } = useTranslation()
	const videoEl = useRef(null)

	const attemptPlay = () => {
		videoEl &&
			videoEl.current &&
			videoEl.current.play().catch(error => {
				console.error('Error attempting to play', error)
			})
	}

	useEffect(() => {
		attemptPlay()
	}, [])

	return (
		<div className='slider__one'>
			<Swiper
				autoplay={{
					delay: 11000,
					disableOnInteraction: false,
				}}
				speed={1000}
				pagination={true}
				navigation={false}
				allowTouchMove={false}
				modules={[Autoplay, Pagination]}
				// effect={'fade'}
				className='oneSliderSwiper'
			>
				<SwiperSlide>
					<div>
						<video
							id='background-video'
							autoPlay
							muted
							loop
							playsInline
							ref={videoEl}
						>
							<source src={oneSlideVideo_1} type='video/mp4' />
						</video>
					</div>
					<div className='slider__one-title anim__op'>
						<p>ZOMB - Панацея</p>
					</div>
					<div className='slider__one-types anim__op'>
						<h3>{t('portfolio')}</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<video id='background-video' autoPlay muted loop playsInline>
							<source src={oneSlideVideo_2} type='video/mp4' />
						</video>
					</div>
					<div className='slider__one-title anim__op'>
						<p>LOOKBOOK Magazine</p>
					</div>
					<div className='slider__one-types anim__op'>
						<h3>{t('portfolio')}</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<video id='background-video' autoPlay muted loop playsInline>
							<source src={oneSlideVideo_3} type='video/mp4' />
						</video>
					</div>
					<div className='slider__one-title anim__op'>
						<p>Vega Platform</p>
					</div>
					<div className='slider__one-types anim__op'>
						<h3>{t('portfolio')}</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<video id='background-video' autoPlay muted loop playsInline>
							<source src={oneSlideVideo_4} type='video/mp4' />
						</video>
					</div>
					<div className='slider__one-title anim__op'>
						<p>Community</p>
					</div>
					<div className='slider__one-types anim__op'>
						<h3>{t('portfolio')}</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<video id='background-video' autoPlay muted loop playsInline>
							<source src={oneSlideVideo_5} type='video/mp4' />
						</video>
					</div>
					<div className='slider__one-title anim__op'>
						<p>Novakid</p>
					</div>
					<div className='slider__one-types anim__op'>
						<h3>{t('portfolio')}</h3>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}

export default SliderOne
