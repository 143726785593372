import React, { useEffect } from 'react'
import gsap from 'gsap'
import $ from 'jquery'

import './index.scss'

import people_photo from '../../img/profile/people.png'

const Profile = () => {
	// useEffect(() => {
	// 	gsap.to($('.profile__loading-start'), 2, {
	// 		delay: 1,
	// 		left: '-100%',
	// 		ease: 'Expo.easeInOut',
	// 	})
	// 	gsap.to($('.profile__loading-state'), 2, {
	// 		delay: 0.6,
	// 		left: '-100%',
	// 		ease: 'Expo.easeInOut',
	// 	})
	// 	setTimeout(() => {
	// 		gsap.to($('.profile__loading'), {
	// 			display: 'none',
	// 		})
	// 	}, 3100)
	// }, [])

	return (
		<>
			<div id='topScroll'></div>
			<div className='profile'>
				{/* <div className='profile__loading'>
				<div className='profile__loading-start'></div>
				<div className='profile__loading-state'></div>
			</div> */}
				<div className='profile__info'>
					<div className='profile__container'>
						<div className='profile__header'>
							<div className='profile__info-title'>Алексей Ефремов</div>
							<div className='profile__info-wrapper'>
								<div className='profile__info-photo'>
									<img src={people_photo} alt='img' />
								</div>
								<div className='profile__info-description'>
									<div className='profile__info-description-i'>
										<h3>Специальность</h3>
										<p>Режиссер</p>
									</div>
									<div className='profile__info-description-l'></div>
									<div className='profile__info-description-i'>
										<h3>О себе</h3>
										<p>
											Продакшн — это процесс создания материальных (сырье,
											полуфабрикаты, запчасти) и нематериальных (идеи,
											информация, знания) ресурсов с целью получить готовый
											продукт, а именно - товар или услугу. Термин чаще всего
											используется в киноиндустрии и сфере рекламы.
										</p>
									</div>
								</div>
								<div className='profile__info-decor'>
									<div className='profile__info-decor-i'></div>
									<div className='profile__info-decor-i'></div>
								</div>
							</div>
						</div>
						<div className='profile__decor'>
							<div className='profile__decor-i'></div>
							<div className='profile__decor-i'></div>
							<div className='profile__decor-i'></div>
							<div className='profile__decor-i'></div>
						</div>
						<div className='profile__list'>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-1.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-2.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-3.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-1.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-2.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-3.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-1.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
							<div className='profile__list-i'>
								<div className='profile__list-p'>
									<img src='../../img/detail/img-2.png' alt='img' />
								</div>
								<div className='profile__list-d'>
									<h3>абсурд</h3>
									<p>Frame style</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Profile
