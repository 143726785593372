import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import Baffle from 'baffle-react'
import $ from 'jquery'
import './index.scss'

const Preloading = () => {
	const preloader = useRef()
	const preloader__text = useRef()

	useEffect(() => {
		gsap.to(preloader.current, 2, {
			delay: 2.5,
			left: '-100%',
			ease: 'Expo.easeInOut',
		})

		if (window.innerHeight <= 660 && window.innerWidth <= 1440) {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '-100%',
				ease: 'Expo.easeInOut',
			})
		} else if (window.innerHeight <= 660 && window.innerWidth <= 1330) {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '-100%',
				ease: 'Expo.easeInOut',
			})
		} else if (window.innerHeight <= 750 && window.innerWidth <= 1330) {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '-100%',
				ease: 'Expo.easeInOut',
			})
		} else if (window.innerHeight <= 850 && window.innerWidth <= 1330) {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '-100%',
				ease: 'Expo.easeInOut',
			})
		} else if (window.innerHeight <= 900 && window.innerWidth <= 1330) {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '-100%',
				ease: 'Expo.easeInOut',
			})
		} else {
			gsap.to(preloader__text.current, 2, {
				delay: 2.5,
				left: '55px',
				ease: 'Expo.easeInOut',
			})
		}

		//-------------

		function randomInteger() {
			let rand = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000
			return Math.floor(rand)
		}

		for (let i = 1; i <= $('.preloading__letter').length; i++) {
			setTimeout(() => {
				$(`.preloading__letter:nth-child(${i})`).addClass(
					'preloading__letter-a'
				)
			}, randomInteger())
		}
	}, [])

	return (
		<div className='preloading__wrapper'>
			{/* <div className='preloading__text' ref={preloader__text}>
				<Baffle
					speed={150}
					characters='●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●'
					obfuscate={false}
					update={true}
					revealDuration={2500}
				>
					BEAUTY&nbsp; IN&nbsp; EVER&nbsp; FRAME
				</Baffle>
			</div> */}
			<div className='preloading__text' ref={preloader__text}>
				<div className='preloading__letter'>V</div>
				<div className='preloading__letter'>I</div>
				<div className='preloading__letter'>S</div>
				<div className='preloading__letter'>U</div>
				<div className='preloading__letter'>A</div>
				<div className='preloading__letter'>L</div>
				<div className='preloading__letter'>&nbsp;</div>
				<div className='preloading__letter'>F</div>
				<div className='preloading__letter'>R</div>
				<div className='preloading__letter'>O</div>
				<div className='preloading__letter'>M</div>
				<div className='preloading__letter'>&nbsp;</div>
				<div className='preloading__letter'>D</div>
				<div className='preloading__letter'>I</div>
				<div className='preloading__letter'>F</div>
				<div className='preloading__letter'>F</div>
				<div className='preloading__letter'>E</div>
				<div className='preloading__letter'>R</div>
				<div className='preloading__letter'>E</div>
				<div className='preloading__letter'>N</div>
				<div className='preloading__letter'>T</div>
				<div className='preloading__letter'>&nbsp;</div>
				<div className='preloading__letter'>C</div>
				<div className='preloading__letter'>O</div>
				<div className='preloading__letter'>R</div>
				<div className='preloading__letter'>U</div>
				<div className='preloading__letter'>N</div>
				<div className='preloading__letter'>E</div>
				<div className='preloading__letter'>R</div>
				<div className='preloading__letter'>S</div>
			</div>

			<div className='preloading__bg' ref={preloader}></div>
		</div>
	)
}

// VISUAL&nbsp; FROM&nbsp; DIFFERENT&nbsp; CORNERS

export default Preloading
