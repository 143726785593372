import gsap from 'gsap';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scroll from 'react-scroll';
import icons__close from '../icons/interface/close.svg';
import svg from '../icons/interface/menu.svg';

import { useTranslation } from 'react-i18next';
import '../util/i18next';
import './interface.scss';

import logo from '../icons/interface/logo.svg';
import logos from '../icons/interface/logos.svg';
import Cursor from './Cursor';
import DoubleClick from './DoubleClick';

let Links = Scroll.Link;

const Interface = () => {
	const { t, i18n } = useTranslation();

	const changleLanguage = lang => {
		i18n.changeLanguage(lang);
	};

	const navigate = useNavigate();

	function checkButton() {
		if (window.location.href.substr(-4) === 'form') {
			$('.interface__link').css('display', 'none');
			$('.interface__close-forms').addClass('display-n');
			$('.interface__close-d-one').addClass('display-n');
			$('.interface__bg-media').addClass('display-n');
			$('.interface__close-d-two').addClass('display-n');

			$('.interface__menu').addClass('interface__menu__close');
			$('.interface__menu').addClass('display-n');
			$('.interface__menu').css('transform', 'rotate(0deg)');
			$('.interface__bg').css('top', '-100%');
			$('.interface__logo').addClass('display-n');
			setTimeout(() => {
				$('.interface__close-forms').removeClass('display-n');
			}, 390);
			setTimeout(() => {
				$('.interface__logo').removeClass('display-n');
			}, 650);
		} else {
			$('.interface__link').css('display', 'block');
			$('.interface__close-forms').addClass('display-n');
			$('.interface__menu').removeClass('display-n');
		}
	}

	useEffect(() => {
		checkButton();
	}, []);

	const redirect__home = () => {
		if (window.location.href.substr(-4) !== 'home') {
			gsap.to($('.interface__loading'), {
				display: 'block',
			});
			gsap.to($('.interface__loading-start'), 2, {
				delay: 0.6,
				left: 0,
				ease: 'Expo.easeInOut',
			});
			gsap.to($('.interface__loading-state'), 2, {
				delay: 1,
				left: 0,
				ease: 'Expo.easeInOut',
			});
			gsap.to($('.interface__loading-text'), 2, {
				delay: 1,
				left: '50%',
				ease: 'Expo.easeInOut',
			});
			setTimeout(() => {
				gsap.to($('.interface__loading'), {
					display: 'none',
				});
				navigate('/home');
				gsap.to($('.interface__loading-start'), {
					delay: 0.1,
					left: '-100%',
				});
				gsap.to($('.interface__loading-state'), {
					delay: 0.1,
					left: '-100%',
				});
				$('.interface__close-forms').addClass('display-n');
				$('.interface__close-d-one').addClass('display-n');
				$('.interface__bg-media').addClass('display-n');
				$('.interface__close-d-two').addClass('display-n');

				$('.interface__menu').removeClass('interface__menu__close');
				$('.interface__menu').removeClass('display-n');
				checkButton();
			}, 3000);
			setTimeout(() => {
				gsap.to($('.interface__loading-text'), {
					left: '-10%',
				});
			}, 3500);
		}
	};

	useEffect(() => {
		$('.interface__menu').on('click', () => {
			if ($('.interface__menu').hasClass('interface__menu__close')) {
				gsap.to($('.interface__bg'), 1, {
					top: '-100%',
					ease: 'Expo.easeInOut',
				});
				$('.interface__menu')
					.removeClass('interface__menu__close')
					.css('transform', 'rotate(90deg)');
			} else {
				gsap.to($('.interface__bg'), 0.8, {
					top: '0',
					ease: 'Expo.easeInOut',
				});
				$('.interface__menu').addClass('interface__menu__close').css('transform', 'rotate(45deg)');
			}
		});

		$('.interface__menu__close').on('click', () => {
			$('.interface__bg').css('display', 'none');
			$('.interface__menu').css('display', 'flex');
		});

		if (window.location.href.substr(-4) === 'home' && window.innerWidth >= 900) {
			gsap.to($('.interface__bg'), 0.8, {
				top: '0',
				ease: 'Expo.easeInOut',
			});
			$('.interface__menu').addClass('interface__menu__close').css('transform', 'rotate(45deg)');
		}
	}, []);

	const [checkLogo, setCheckLogo] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (t('logo') === 0) {
				setCheckLogo(0);
			} else {
				setCheckLogo(1);
			}
		}, 100);
		for (let i = 1; i <= $('.interface__lang-b').length; i++) {
			$(`.interface__lang-b:nth-child(${i})`).on('click', () => {
				$('.interface__lang-b').removeClass('interface__lang-a');
				$('.interface__lang-b')
					.eq(i - 1)
					.addClass('interface__lang-a');
			});
		}
	}, [t('logo')]);

	return (
		<div className='interface'>
			<DoubleClick classN='interface__logo'>
				<div className='interface__logo' onClick={redirect__home}>
					{checkLogo === 0 ? <img src={logo} alt='logo' /> : <img src={logos} alt='logo' />}
				</div>
			</DoubleClick>
			<DoubleClick classN='interface__menu'>
				<button className='interface__menu cursor__act'>
					<img src={svg} alt='menu' />
				</button>
			</DoubleClick>

			<Links
				to='topScroll'
				spy={true}
				smooth={true}
				duration={1000}
				delay={100}
				className='interface__close-d-one display-n cursor__cross'
			>
				<img src={icons__close} alt='menu' />
				<p>{t('back')}</p>
			</Links>
			<Links
				to='topScroll'
				spy={true}
				smooth={true}
				duration={1000}
				delay={100}
				className='interface__close-d-two display-n cursor__cross'
			>
				<img src={icons__close} alt='menu' />
				<p>{t('back')}</p>
			</Links>
			<Links
				to='teamScroll'
				spy={true}
				smooth={true}
				duration={1000}
				delay={100}
				className='interface__close-profile display-n cursor__cross'
			>
				<img src={icons__close} alt='menu' />
				<p>{t('back')}</p>
			</Links>
			<div className='interface__bg-media display-n'></div>

			<DoubleClick classN='interface__close-forms'>
				<div className='interface__close-forms display-n cursor__cross' onClick={redirect__home}>
					<img src={icons__close} alt='menu' />
					<p>{t('back')}</p>
				</div>
			</DoubleClick>
			<div className='interface__bg'>
				<div className='interface__controller cursor__act'></div>
				<div className='interface__menus'>
					<a
						href='https://vk.com/smotrishire_agency'
						className='interface__menus-instagram cursor__act'
						target='_blank'
						rel='noopener noreferrer'
					>
						VKONTAKTE
					</a>
					<a
						href='https://vimeo.com/smotrishire'
						className='interface__menus-vimeo cursor__act'
						target='_blank'
						rel='noopener noreferrer'
					>
						VIMEO
					</a>
					<a href='mailto:pr@smotrishire.com' className='interface__menus-email cursor__act'>
						pr@smotrishire.com
					</a>
					<a href='tel:+79223773777' className='interface__menus-phone cursor__act'>
						+7 922 377 3777
					</a>
				</div>
				<div className='interface__lang'>
					<button
						onClick={() => changleLanguage('ru')}
						className={`interface__lang-b ${
							checkLogo === 0 ? 'interface__lang-a' : ''
						} cursor__act`}
					>
						RU
					</button>
					<button
						onClick={() => changleLanguage('en')}
						className={`interface__lang-b ${
							checkLogo === 1 ? 'interface__lang-a' : ''
						} cursor__act`}
					>
						EN
					</button>
				</div>
			</div>
			<div className='interface__loading'>
				<div className='interface__loading-start'></div>
				<div className='interface__loading-state'></div>
				<div className='interface__loading-text'>●●●●●●●●●●●●●●●●●●●●</div>
				<div className='interface__loading-text'>
					<div className='interface__loading-letter'>V</div>
					<div className='interface__loading-letter'>I</div>
					<div className='interface__loading-letter'>S</div>
					<div className='interface__loading-letter'>U</div>
					<div className='interface__loading-letter'>A</div>
					<div className='interface__loading-letter'>L</div>
					<div className='interface__loading-letter'>&nbsp;</div>
					<div className='interface__loading-letter'>F</div>
					<div className='interface__loading-letter'>R</div>
					<div className='interface__loading-letter'>O</div>
					<div className='interface__loading-letter'>M</div>
					<div className='interface__loading-letter'>&nbsp;</div>
					<div className='interface__loading-letter'>D</div>
					<div className='interface__loading-letter'>I</div>
					<div className='interface__loading-letter'>F</div>
					<div className='interface__loading-letter'>F</div>
					<div className='interface__loading-letter'>E</div>
					<div className='interface__loading-letter'>R</div>
					<div className='interface__loading-letter'>E</div>
					<div className='interface__loading-letter'>N</div>
					<div className='interface__loading-letter'>T</div>
					<div className='interface__loading-letter'>&nbsp;</div>
					<div className='interface__loading-letter'>C</div>
					<div className='interface__loading-letter'>O</div>
					<div className='interface__loading-letter'>R</div>
					<div className='interface__loading-letter'>U</div>
					<div className='interface__loading-letter'>N</div>
					<div className='interface__loading-letter'>E</div>
					<div className='interface__loading-letter'>R</div>
					<div className='interface__loading-letter'>S</div>
				</div>
			</div>
			<Cursor />
		</div>
	);
};

export default Interface;
