import React, { useRef, useEffect, useState } from 'react'
// import { ParallaxProvider } from 'react-scroll-parallax'

import hoverEffect from 'hover-effect'

import $ from 'jquery'

import Marquee from 'react-fast-marquee'

import WOW from 'wowjs'

import web__m from '../../icons/details/marquee/web.svg'
import film__m from '../../icons/details/marquee/film.svg'
import design__m from '../../icons/details/marquee/design.svg'

import './index.scss'
import DetailsFooter from '../DetailsFooter/DetailsFooter'
import Profile from '../Profile/Profile'
import Tabs from './Tabs'

import Scroll from 'react-scroll'

import { useTranslation } from 'react-i18next'
import '../../util/i18next'

import arrowsTopIcons from '../../icons/details/up.svg'

let Links = Scroll.Link

const DetailsTwoSlide = () => {
	const { t } = useTranslation()

	const glish_ = useRef()

	const [profilePage, setProfilePage] = useState(false)

	useEffect(() => {
		if (profilePage === true) {
			$('.detailTwo').css('height', '0').css('padding', '0')
			$('.interface__close-profile').removeClass('display-n')
			$('.interface__close-d-two').addClass('display-n')
		} else {
			$('.detailTwo').css('height', '100%').css('padding', '600px 0 0 0')
			$('.interface__close-profile').addClass('display-n')
			$('.interface__close-d-two').removeClass('display-n')
		}
	}, [profilePage])

	const openProfile = () => {
		// setProfilePage(true)
	}

	const part__icons = [
		'icons-1',
		'icons-2',
		'icons-3',
		'icons-4',
		'icons-5',
		'icons-6',
		'icons-7',
		'icons-8',
		'icons-9',
		'icons-10',
		'icons-11',
		'icons-12',
		'icons-13',
		'icons-14',
		'icons-15',
	]

	useEffect(() => {
		// if (window.innerWidth >= 900) {
		// 	new hoverEffect({
		// 		parent: glish_.current,
		// 		intensity: 0.3,
		// 		imagesRatio: 875 / 550,
		// 		image1:
		// 			'https://upload.wikimedia.org/wikipedia/commons/7/7c/ГЛИЧ_2.jpg',
		// 		image2:
		// 			'https://upload.wikimedia.org/wikipedia/commons/6/67/ГЛИЧ_1.jpg',
		// 		displacementImage:
		// 			'https://raw.githubusercontent.com/codegridweb/Image-Hover-Liquid-Distortion-Effect-ThreeJS-TweenMax/master/4.png',
		// 	})
		// }
		new hoverEffect({
			parent: glish_.current,
			intensity: 0.3,
			imagesRatio: 875 / 550,
			image1: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/ГЛИЧ_2.jpg',
			image2: 'https://upload.wikimedia.org/wikipedia/commons/6/67/ГЛИЧ_1.jpg',
			displacementImage:
				'https://raw.githubusercontent.com/codegridweb/Image-Hover-Liquid-Distortion-Effect-ThreeJS-TweenMax/master/4.png',
		})
	}, [glish_])

	useEffect(() => {
		if (window.innerWidth <= 900) {
			$('.animated').removeClass('animated')
		}

		new WOW.WOW({
			live: false,
		}).init()

		for (var i = 1; i <= $('.detailTwo__team-w').length; i++) {
			$(`#teamA__${i}`).hover(
				function () {
					$(this).removeClass('detailTwo__team-w__a')
				},
				function () {
					$(this).addClass('detailTwo__team-w__a')
				}
			)
		}

		$('.interface__close-profile').on('click', () => {
			setProfilePage(false)
		})

		$('#detailTwo').scroll(function () {
			if (this.scrollTop >= 230) {
				$('.arrowTopTwo').removeClass('display-n')
			}
			console.log(1)
		})

		$('.arrowTopTwo').on('click', () => {
			details.current.scrollTo(0, 0)
		})
	}, [])

	const details = useRef(null)

	//! 7
	const marquee__person = {
		1: [
			'Zakhar Starikov',
			'Daniil Artemenko',
			'Evgenii Kochetkov',
			'Igor Shilov',
			'Alice Pozhidaeva',

			'Zakhar Starikov',
			'Daniil Artemenko',
			'Evgenii Kochetkov',
			'Igor Shilov',
			'Alice Pozhidaeva',
		],
		2: [
			'Ilya Kopachev',
			'Ivan Osipov',
			'Alex Drebesgin',
			'Mark Torpan',
			'Dmitriy Eliseev',

			'Ilya Kopachev',
			'Ivan Osipov',
			'Alex Drebesgin',
			'Mark Torpan',
			'Dmitriy Eliseev',
		],
		3: [
			'Maxim Romanov',
			'Helen Dyupina',
			'Nikita Bildanov',
			'Aital Alekseev',
			'Dmitrii Popov',
			'Dmitrii Pshenichnykh',

			'Maxim Romanov',
			'Helen Dyupina',
			'Nikita Bildanov',
			'Aital Alekseev',
			'Dmitrii Popov',
			'Dmitrii Pshenichnykh',
		],
	}

	return (
		<>
			<section className='detailTwo' ref={details} id='detailTwo'>
				<div className='arrowTopTwo display-n cursor__act'>
					<img src={arrowsTopIcons} alt='icons' />
				</div>
				<div className='detailTwo__redirect'>
					<div className='detailTwo__redirect-start'></div>
					<div className='detailTwo__redirect-state'></div>
				</div>
				<Marquee className='detailTwo__line' speed={70} direction='left'>
					<img src={web__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
					<img src={film__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
					<img src={design__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
					<img src={web__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
					<img src={film__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
					<img src={design__m} alt='icons' />
					<div className='detailTwo__line-p'></div>
				</Marquee>
				<div className='detailTwo__container'>
					<div className='detailTwo__decor'>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
						<div className='detailTwo__decor-i'></div>
					</div>
					{/* <div
						className='detailTwo__description detailTwo__description-right wow fadeIn animated'
						data-wow-delay='0.5s'
					>
						<p>Смотри Шире</p>
						<span>
							независимое креативное агентство и философия нашей работы
						</span>
					</div>
					<div
						className='detailTwo__description detailTwo__description-left wow fadeIn animated'
						data-wow-delay='0.5s'
					>
						<p>Что мы делаем?</p>
						<span>
							Инновационные решения в сфере цифрового контента. Любой продукт от
							начальной концепции до финального результата.
						</span>
					</div> */}
					<div className='detailTwo__description'>
						<div className='detailTwo__description-t'>{t('title')}</div>
						<div className='detailTwo__description-d'>
							{t('description.one')}
							<br /> {t('description.two')}
							<br />
							{t('description.three')}
						</div>
						<div className='detailTwo__description-g'>
							<div className='detailTwo__description-glish' ref={glish_}></div>
						</div>
						<div className='detailTwo__description-b'>
							<h3>{t('question')}</h3>
							<p>{t('answer')}</p>
						</div>
					</div>
				</div>
				<div className='detailTwo__tab'>
					<Tabs />
				</div>
				<div id='teamScroll'></div>
				<div className='detailTwo__team'>
					<div className='detailTwo__team-t'>{t('team')}</div>
					<div id='teamA__1' className='detailTwo__team-w detailTwo__team-w__a'>
						<div className='detailTwo__team-track'>
							{marquee__person[1].map((n, i) => (
								<Links
									key={i}
									to=''
									spy={true}
									smooth={true}
									duration={10}
									delay={0}
									onClick={openProfile}
									className='detailTwo__team-text cursor__act'
								>
									{n}
									<div className='detailTwo__team-e'></div>
								</Links>
							))}
						</div>
					</div>
					<div id='teamA__2' className='detailTwo__team-w detailTwo__team-w__a'>
						<div className='detailTwo__team-track detailTwo__team-r'>
							{marquee__person[2].map((n, i) => (
								<Links
									key={i}
									to=''
									spy={true}
									smooth={true}
									duration={10}
									delay={0}
									onClick={openProfile}
									className='detailTwo__team-text cursor__act'
								>
									{n}
									<div className='detailTwo__team-e'></div>
								</Links>
							))}
						</div>
					</div>
					<div id='teamA__3' className='detailTwo__team-w detailTwo__team-w__a'>
						<div className='detailTwo__team-track'>
							{marquee__person[3].map((n, i) => (
								<Links
									key={i}
									to=''
									//! topScroll
									spy={true}
									smooth={true}
									duration={10}
									delay={0}
									onClick={openProfile}
									className='detailTwo__team-text cursor__act'
								>
									{n}
									<div className='detailTwo__team-e'></div>
								</Links>
							))}
						</div>
					</div>
				</div>

				<div className='detailTwo__container'>
					<div className='detailTwo__partner'>
						<h2 className='detailTwo__partner-t'>{t('client')}</h2>
						<ul className='detailTwo__partner-l'>
							{part__icons.map((item, index) => (
								<li key={index} className='detailTwo__partner-i'>
									<img
										src={require('../../img/detail_2/client/' + item + '.png')}
										alt='icons'
									/>
								</li>
							))}
						</ul>
					</div>
				</div>

				<DetailsFooter />
			</section>

			{profilePage ? <Profile /> : null}
		</>
	)
}

export default DetailsTwoSlide
