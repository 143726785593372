import React, { useEffect, useState } from 'react'
import $ from 'jquery'

// const classNameButton = [
// 	// 'interface__link',
// 	'interface__logo',
// 	'interface__close-forms',
// ]

const DoubleClick = ({ classN, delay = 1500, children }) => {
	// Fix double click button

	const [active, setActive] = useState(false)

	useEffect(() => {
		// classNameButton.map(items =>
		// 	$(`.${items}`).on('click', function () {
		// 		setActive(true)
		// 		setTimeout(() => {
		// 			setActive(false)
		// 		}, 1500)
		// 	})
		// )
		$(`.${classN}`).on('click', function () {
			setActive(true)
			setTimeout(() => {
				setActive(false)
			}, delay)
		})
	}, [])

	return (
		<div className='doubleClick'>
			{children}
			{active ? <div className='doubleClick__b'></div> : null}
		</div>
	)
}

export default DoubleClick
