import React, { useEffect, useRef } from 'react'

import icons__close from '../../icons/interface/close.svg'

import $ from 'jquery'

import './index.scss'
import Cursor from '../../interface/Cursor'

const ModalVideo = () => {
	const closeButton = useRef()
	const modal = useRef()

	useEffect(() => {
		$(closeButton.current).on('click', function () {
			$('body').css('overflow', 'visible')
			$(modal.current).css('opacity', '0')
			setTimeout(() => {
				$(modal.current).css('display', 'none')
				$('.ModalVideo__video iframe').attr('src', '')
			}, 550)
		})
	}, [])

	return (
		<div
			className='ModalVideo'
			style={{ opacity: 0, display: 'none' }}
			ref={modal}
		>
			<div className='ModalVideo__containers'>
				<div className='ModalVideo__close cursor__cross' ref={closeButton}>
					<img src={icons__close} alt='close' />
				</div>
				<div className='ModalVideo__video'>
					<iframe
						title='vimeo-player'
						src=''
						width='100%'
						height='100%'
						frameborder='0'
						allowfullscreen
					></iframe>
				</div>
			</div>
			<Cursor />
		</div>
	)
}

export default ModalVideo
