import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './components/Home/Home'
import Interface from './interface/Interface'
import Form from './components/Form/Form'
// import Error from './components/Error/Error'
import Profile from './components/Profile/Profile'

function App() {
	return (
		<Suspense fallback={''}>
			<Interface />
			<Routes>
				<Route path='/home' element={<Home />} />
				<Route path='/form' element={<Form />} />
				<Route path='/profile' element={<Profile />} />
				<Route path='/' element={<Navigate to='/home' />} />
				<Route path='' element={<Navigate to='/home' />} />
				<Route path='/index' element={<Navigate to='/home' />} />
				{/* <Route path='*' element={<Error />} /> */}
			</Routes>
		</Suspense>
	)
}

export default App
