import React, { useEffect } from 'react'
import gsap from 'gsap'
import $ from 'jquery'

import './index.scss'

// import banner__img from '../../img/form/banner.jpg'

import { useTranslation } from 'react-i18next'

const Form = () => {
	const { t } = useTranslation()

	useEffect(() => {
		gsap.to($('.form__loading-start'), 1.2, {
			delay: 0.3,
			left: '-100%',
			ease: 'Expo.easeInOut',
		})
		gsap.to($('.form__loading-state'), 1, {
			delay: 0.2,
			left: '-100%',
			ease: 'Expo.easeInOut',
		})
		setTimeout(() => {
			gsap.to($('.form__loading'), {
				display: 'none',
			})
		}, 3100)
		$('.interface__bg-media').addClass('display-n')

		$('#form__i').hover(
			() => {
				$('.cursor').addClass('cursor__h')
				$('.cursor2').addClass('cursor2__h')
			},
			() => {
				$('.cursor').removeClass('cursor__h')
				$('.cursor2').removeClass('cursor2__h')
			}
		)
		document.getElementById('form__i').onload = iframeOnload

		function iframeOnload() {
			document.querySelector('.iframe__block p').remove()
		}
	}, [])

	return (
		<div className='form'>
			<div className='form__loading'>
				<div className='form__loading-start'></div>
				<div className='form__loading-state'></div>
			</div>
			<div className='form__wrapper'>
				<div className='form__wrapper-info'>
					<div className='form__wrapper-titles'>
						<p>{t('form.one')}</p>
						<p>{t('form.two')}</p>
					</div>
					<div className='iframe__block'>
						<p>Loading...</p>
						<iframe
							title={'title'}
							id='form__i'
							src='https://smotrishire.com/forms/'
						></iframe>
					</div>
				</div>
				{/* <div className='form__wrapper-banner'>
					<img src={banner__img} alt='img' />
				</div> */}
			</div>
		</div>
	)
}

export default Form
