// import React, { useState } from 'react'
// import TabContent from './TabContent'

// const tabs__i = [
// 	{
// 		id: 1,
// 		button: 'FILM',
// 		title: 'Продакшн',
// 		text: [
// 			'Команда занимается производством фото и видео контента: рекламы, музыкальные клипы, кино и другие направления.',
// 			'Наши специалисты ведут создание проекта от формирования концепции до выдачи финального результата.',
// 		],
// 		obj__1: 1,
// 		title__1: 'PRE-PRODUCTION',
// 		descr__1: [
// 			'Разработка рекламных стратегий и сценариев',
// 			'Подбор и менеджмент команд',
// 			'Техническое обеспечение и организация',
// 		],
// 		obj__2: 1,
// 		title__2: 'PRODUCTION',
// 		descr__2: ['Обеспечение логистики', 'Проведение съемок'],
// 		obj__3: 1,
// 		title__3: 'POST-PRODUCTION',
// 		descr__3: [
// 			'Монтаж, VFX и SFX, 2D и 3D моделирование, цветокоррекция',
// 			'Озвучивание, музыкальный продакшн, звуковой дизайн',
// 		],
// 	},
// 	{
// 		id: 2,
// 		button: 'WEB',
// 		title: '',
// 		text: [
// 			'Команда профессиональных web разработчиков и дизайнеров занимается производством сайтов разной сложности, от продающих лендингов до сложных функциональных систем для больших корпораций.',
// 		],
// 		obj__1: 1,
// 		title__1: 'PRE-PRODUCTION',
// 		descr__1: [
// 			'Определение технической задачи сайта',
// 			'Создание макета сайта и проработка UX и UI логики',
// 			'Создание дизайна страниц согласно брендбуку клиента и макету сайта',
// 		],
// 		obj__2: 1,
// 		title__2: 'PRODUCTION',
// 		descr__2: [
// 			'Верстка страниц сайта',
// 			'Создание личного кабинета, CRM, поиска, каталога',
// 		],
// 		obj__3: 1,
// 		title__3: 'POST-PRODUCTION',
// 		descr__3: ['Техническое обслуживание сайта', 'Добавление новых страниц'],
// 	},
// 	{
// 		id: 3,
// 		button: 'DESIGN',
// 		title: '',
// 		text: [
// 			'Команда дизайна занимается производством визуальных решений для разных потребностей: от формирования внешнего образа: логотип, брендбук, графические элементы, презентации и другие продукты.',
// 			'А также создает взаимодействия с потребителем: печатная продукция, рекламные баннеры, контент в соцсетях.',
// 		],
// 		obj__1: 1,
// 		title__1: 'PRE-PRODUCTION',
// 		descr__1: [
// 			'Определение потребностей клиента (логотип, фирменный стиль, визуальное оформление соцсетей и рекламы, иллюстрации, упаковки и мерч и тд)',
// 			'Анализ продукта, компании и конкурентов',
// 			'Составление технического задания',
// 		],
// 		obj__2: 1,
// 		title__2: 'PRODUCTION',
// 		descr__2: ['Генерация идей и концепций', 'Разработка макетов'],
// 		obj__3: 1,
// 		title__3: 'POST-PRODUCTION',
// 		descr__3: [
// 			'Внесение корректировок',
// 			'Подготовка макетов к реализации/печати',
// 		],
// 	},
// 	{
// 		id: 4,
// 		button: 'MUSIC',
// 		title: '',
// 		text: [
// 			'Команда саунд-дизайна занимается производством любого вида аудиоконтента, от музыкального сопровождения рекламы, видеоклипов, кино, трейлеров и других направлений любой сложности.',
// 			'Также предоставляют композиторские услуги, написание песен под ключ, разработку аранжировок и другие виды работ с аудио.',
// 		],
// 		obj__1: 1,
// 		title__1: 'PRE-PRODUCTION',
// 		descr__1: [
// 			'Выбор направленности звукового ряда',
// 			'Определение количества необходимых аудио дорожек и их длины',
// 			'Техническое сопровождение команды “FILM”',
// 		],
// 		obj__2: 1,
// 		title__2: 'PRODUCTION',
// 		descr__2: ['Написание аудио дорожек'],
// 		obj__3: 0,
// 		title__3: 'POST-PRODUCTION',
// 		descr__3: [],
// 	},
// ]

// function Tabs({ items }) {
// 	const [active, setActive] = useState(0)

// 	const openTab = e => {
// 		setActive(+e.target.dataset.index)
// 		setSelectedOption(e)
// 		setIsOpen(false)
// 	}

// 	const [isOpen, setIsOpen] = useState(false)
// 	const [selectedOption, setSelectedOption] = useState(null)

// 	const toggling = () => setIsOpen(!isOpen)

// 	return (
// 		<div className='detailTwo__tabs'>
// 			<div className='cSelect__containers'>
// 				<div className='cSelect__header' onClick={toggling}>
// 					{items[selectedOption].title || items[0].titles}
// 				</div>
// 				{isOpen && (
// 					<div className='cSelect__lists'>
// 						<ul className='cSelect__list'>
// 							{items.map((n, i) => (
// 								<button
// 									className='cSelect__items'
// 									onClick={openTab}
// 									key={i}
// 									data-index={i}
// 								>
// 									{n}
// 								</button>
// 							))}
// 						</ul>
// 					</div>
// 				)}
// 			</div>
// 			{<TabContent obj={items[active]} />}
// 		</div>
// 	)
// }
// export default Tabs

import { useState, useEffect } from 'react'
import Cursor from '../../interface/Cursor'
import TabContent from './TabContent'
import { useTranslation } from 'react-i18next'
import '../../util/i18next'

// const items = [
// 	{ title: 'London', content: 'London is the capital city of England.' },
// 	{ title: 'Paris', content: 'Paris is the capital of France.' },
// 	{ title: 'Tokyo', content: 'Tokyo is the capital of Japan.' },
// ]

const Tabs = () => {
	const { t } = useTranslation()

	const items = [
		{
			id: 1,
			button: 'FILM',
			title: '',
			text: [t('film.text'), t('film.desc')],
			obj__1: 1,
			title__1: 'PRE-PRODUCTION',
			descr__1: [t('film.pre.one'), t('film.pre.two'), t('film.pre.three')],
			obj__2: 1,
			title__2: 'PRODUCTION',
			descr__2: [t('film.pro.one'), t('film.pro.two')],
			obj__3: 1,
			title__3: 'POST-PRODUCTION',
			descr__3: [t('film.post.one'), t('film.post.two')],
		},
		{
			id: 2,
			button: 'WEB',
			title: '',
			text: [t('web.text'), '\n\n'],
			obj__1: 1,
			title__1: 'PRE-PRODUCTION',
			descr__1: [t('web.pre.one'), t('web.pre.two'), t('web.pre.three')],
			obj__2: 1,
			title__2: 'PRODUCTION',
			descr__2: [t('web.pro.one'), t('web.pro.two')],
			obj__3: 1,
			title__3: 'POST-PRODUCTION',
			descr__3: [t('web.post.one'), t('web.post.two')],
		},
		{
			id: 3,
			button: 'DESIGN',
			title: '',
			text: [t('design.text'), t('design.desc')],
			obj__1: 1,
			title__1: 'PRE-PRODUCTION',
			descr__1: [
				t('design.pre.one'),
				t('design.pre.two'),
				t('design.pre.three'),
			],
			obj__2: 1,
			title__2: 'PRODUCTION',
			descr__2: [t('design.pro.one'), t('design.pro.two')],
			obj__3: 1,
			title__3: 'POST-PRODUCTION',
			descr__3: [t('design.post.one'), t('design.post.two')],
		},
		{
			id: 4,
			button: 'MUSIC',
			title: '',
			text: [t('music.text'), t('music.desc')],
			obj__1: 1,
			title__1: 'PRE-PRODUCTION',
			descr__1: [t('music.pre.one'), t('music.pre.two'), t('music.pre.three')],
			obj__2: 1,
			title__2: 'PRODUCTION',
			descr__2: [t('music.pro.one')],
			obj__3: 0,
			title__3: 'POST-PRODUCTION',
			descr__3: [],
		},
	]

	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState(0)
	const [active, setActive] = useState(0)

	const openTab = e => {
		setActive(+e.target.dataset.index)
		setSelectedOption(e.target.dataset.index)
		if (window.innerWidth <= 900) {
			setIsOpen(false)
		} else {
			setIsOpen(true)
		}
	}

	useEffect(() => {
		if (window.innerWidth <= 900) {
			setIsOpen(false)
		} else {
			setIsOpen(true)
		}
	}, [])

	const toggling = () => setIsOpen(!isOpen)

	return (
		<>
			<div
				className={`detailTwo__tabs-header ${
					isOpen ? '' : 'detailTwo__tabs-header__a'
				}`}
				onClick={toggling}
			>
				{items[selectedOption].button || items[0].button}
			</div>
			<Cursor />
			{isOpen && (
				<div className='detailTwo__tabs-btns'>
					{items.map((n, i) => (
						<button
							className={`detailTwo__tabs-btn cursor__act ${
								i === active ? 'detailTwo__tabs-btn__a' : ''
							}`}
							onClick={
								openTab
								// onOptionClicked(n)
							}
							data-index={i}
							key={Math.random()}
						>
							{n.button}
						</button>
					))}
				</div>
			)}
			{items[active] && <TabContent {...items[active]} />}
		</>
	)
}

export default Tabs
