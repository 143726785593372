const TabContent = ({
	id,
	button,
	title,
	text,
	obj__1,
	title__1,
	descr__1,
	obj__2,
	title__2,
	descr__2,
	obj__3,
	title__3,
	descr__3,
}) => (
	<>
		<div className='detailTwo__tabs-bg'>
			<img src={require('../../img/detail_2/tabs/' + id + '.jpg')} alt='img' />
		</div>
		<div key={id.toString()} className='detailTwo__tabs-c'>
			<h3 className='detailTwo__tabs-t'>{title}</h3>
			<p className='detailTwo__tabs-text'>
				{text.length === 0
					? ''
					: text.map((text, i) => (
							<p key={i}>
								{text} <br />
							</p>
					  ))}
			</p>
			<ul className='detailTwo__tabs-b'>
				{obj__1 === 0 ? (
					''
				) : (
					<li className='detailTwo__tabs-i'>
						<div className='detailTwo__tabs-titles'>{title__1}</div>
						<div className='detailTwo__tabs-descriptor'>
							{descr__1.map((items, id) => (
								<div key={id}>{items}</div>
							))}
						</div>
					</li>
				)}
				{obj__2 === 0 ? (
					''
				) : (
					<li className='detailTwo__tabs-i'>
						<div className='detailTwo__tabs-titles'>{title__2}</div>
						<div className='detailTwo__tabs-descriptor'>
							{descr__2.map((items, id) => (
								<div key={id}>{items}</div>
							))}
						</div>
					</li>
				)}
				{obj__3 === 0 ? (
					''
				) : (
					<li className='detailTwo__tabs-i'>
						<div className='detailTwo__tabs-titles'>{title__3}</div>
						<div className='detailTwo__tabs-descriptor'>
							{descr__3.map((items, id) => (
								<div key={id}>{items}</div>
							))}
						</div>
					</li>
				)}
			</ul>
		</div>
	</>
)

export default TabContent
